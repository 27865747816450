/**
 * Sets up `react-select`
 */
import Select from 'react-select';

// Styles for react-select are global
// eslint-disable-next-line no-unused-vars
import styles from './Select.scss';

export default Select;
